:root {
  --code-color: darkred;
  --code-bg-color: #f6f6f6;
  --code-font-size: 14px;
  --code-line-height: 1.4;
  --scroll-bar-color: #c5c5c5;
  --scroll-bar-bg-color: #f6f6f6;
}
html, body {
  min-height: 100%;
  background-color: #fff;
  }
  body, div, form, input, select { 
  padding: 0;
  margin: 0;
  outline: none;
  font-family: Roboto, Arial, sans-serif;
  font-size: 14px;
  color: #666;
  line-height: 22px;
  }
  h1, h4 {
  margin: 15px 0 4px;
  font-weight: 400;
  }
  span {
  color: red;
  }
  .testbox {
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
  padding: 3px;
  width: 1060px;
  }
  form {
  width: 60%;
  padding: 20px;
  background: #fff;
  box-shadow: 0 2px 5px #ccc; 
  border-radius: 10px;
  }
  input {
  width: calc(60% - 10px);
  padding: 5px;
  border: 2px solid #6c6c6c;
  border-radius: 3px;
  vertical-align: middle;
  }
  input:hover, textarea:hover, select:hover {
  outline: none;
  border: 2px solid #095484;
  }
  .name input {
  margin-bottom: 10px;
  }
  select {
  padding: 7px 0;
  border-radius: 3px;
  border: 2px solid #6c6c6c;
  background: #e6eef7;
  }
  option {
  background: #fff;
  }
  select, table {
  width: 100%;
  }
  input[type="checkbox"] label {
    cursor: pointer;
  }
  .day-visited, .time-visited {
  position: relative;
  }
  .day-visited input, .time-visited input {
  width: calc(100% - 12px);
  background: #e6eef7;
  }
  input[type="date"]::-webkit-inner-spin-button {
  display: none;
  }
  input[type="time"]::-webkit-inner-spin-button {
  margin: 2px 22px 0 0;
  }
  .day-visited i, .time-visited i, input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 8px;
  font-size: 20px;
  }
  .day-visited i, .time-visited i {
  right: 5px;
  z-index: 1;
  color: #a9a9a9;
  }
  [type="date"]::-webkit-calendar-picker-indicator {
  right: 0;
  z-index: 2;
  opacity: 0;
  }
  .question-answer label {
  display: block;
  padding: 0 20px 10px 0;
  }
  .question-answer input {
  width: auto;
  margin-top: -2px;
  }
  th, td {
  width: 18%;
  padding: 15px 0;
  border-bottom: 1px solid #ccc;
  text-align: center;
  vertical-align: unset;
  line-height: 18px;
  font-weight: 400;
  word-break: break-all;
  }
  .first-col {
  width: 25%;
  text-align: left;
  }
  textarea {
  width: calc(100% - 6px);
  }
  .btn-block {
  margin-top: 20px;
  text-align: center;
  }
  button {
  width: 150px;
  padding: 10px;
  border: none;
  -webkit-border-radius: 5px; 
  -moz-border-radius: 5px; 
  border-radius: 5px; 
  background-color: #095484;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
  }
  button:hover {
  background-color: #0666a3;
  }
  @media (min-width: 568px) {
  .name {
  display: flex;
  justify-content: flex-start;
  gap: 22px;
  }
  .name input {
  width: 47%;
  margin-bottom: 0;
  }
  th, td {
  word-break: keep-all;
  }
  }
.customer_names {
  width: 30% !important;
}
.select_class1 {
  width: 50% !important;
}



pre {
  color: var(--code-color);
  font-size: var(--code-font-size);
  line-height: var(--code-line-height);
  background-color: var(--code-bg-color);
}

.code-block {
  max-height: 100px;
  overflow: auto;
  padding: 8px 7px 5px 15px;
  margin: 0px 0px 0px 0px;
  border-radius: 7px;
}

* {
  scrollbar-width: thin;
  scrollbar-color: var(--scroll-bar-color) var(--scroll-bar-bg-color);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: var(--scroll-bar-bg-color);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--scroll-bar-color);
  border-radius: 20px;
  border: 3px solid var(--scroll-bar-bg-color);
}


::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #c0bfbf;
}
::-moz-placeholder { /* Firefox 19+ */
  color: #b0afaf;
}
:-ms-input-placeholder { /* IE 10+ */
  color: #b0afaf;
}
:-moz-placeholder { /* Firefox 18- */
  color: #b0afaf;
}